import React from "react"
import { Box, type BoxProps } from "@chakra-ui/layout"

export default function GradientDivider(props: BoxProps) {
    return (
        <Box
            bgGradient="linear(to-r, gray.50, gray.200, gray.50)"
            h="1px"
            flex={1}
            {...props}
        />
    )
}
