import React, { useContext } from "react"
import { Link } from "gatsby"
import { CloseIcon } from "@chakra-ui/icons"
import { IconButton } from "@chakra-ui/button"
import { Flex, Text } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import { format, addMonths, startOfMonth, addDays } from "date-fns"

import AmazonGiftCard from "~images/layout/header/other/az-gc.png"
import { OfferStateContext } from "~utils/providers/offer-state"
import RainbowText from "../text/rainbow-text"

export const SPECIAL_OFFER_BANNER_HEIGHT = 0 // 52
export const GIFT_CARD_AMOUNT = 40

export default function SpecialOfferBanner() {
    const { setIsOfferOpen } = useContext(OfferStateContext)

    const formattedEndOfMonth = getEndOfMonthFormatted(new Date())

    return (
        <Flex
            as={Link}
            to="/book-a-demo"
            w="full"
            p={2}
            pr={6}
            bgGradient="linear(to-r, purple.600, orange.400, pink.400, blue.400)"
            position="sticky"
            flexDir="row"
            justifyContent="center"
            alignItems="center"
            top={0}
            gap={2}
            h={`${SPECIAL_OFFER_BANNER_HEIGHT}px`}
            zIndex={999999999999}
        >
            <Image src={AmazonGiftCard} height="24px" rounded="md" />
            <Text
                fontSize={{ base: "xs", md: "md" }}
                color="white"
                fontWeight="bold"
            >
                Book a demo by {formattedEndOfMonth} and get a{" "}
                <RainbowText as="span" fontWeight="black">
                    ${GIFT_CARD_AMOUNT} Amazon
                </RainbowText>{" "}
                gift card for you AND a Colleague!*
            </Text>
            <Image
                src={AmazonGiftCard}
                height="24px"
                rounded="md"
                display={{
                    base: "none",
                    md: "block",
                }}
            />
            <IconButton
                pos="absolute"
                boxSize={{ base: 4, md: 6 }}
                right={2}
                aria-label="Close Offer"
                color="white"
                icon={<CloseIcon />}
                onClick={e => {
                    e.preventDefault()
                    setIsOfferOpen(false)
                }}
                bg="transparent"
                _hover={{ bg: "transparent" }}
            />
        </Flex>
    )
}

export function getLastDayOfMonth(date: Date): Date {
    const nextMonth = addMonths(date, 1)
    const beginningOfNextMonth = startOfMonth(nextMonth)
    const endOfThisMonth = addDays(beginningOfNextMonth, -1)

    return endOfThisMonth
}

/**
 * @param date The date to get the end of the month for
 * @param formatter The format to return the end of the month in, defaults to "MMMM do" (e.g. "January 31st")
 * @returns The formatted end of the month
 */
export function getEndOfMonthFormatted(
    date: Date,
    formatter = "MMMM do"
): string {
    const endOfThisMonth = getLastDayOfMonth(date)
    const formattedEndOfMonth = format(endOfThisMonth, formatter)

    return formattedEndOfMonth
}
