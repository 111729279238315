import React from "react"
import { Link } from "gatsby"
import { Button, ButtonProps, Text, Box } from "@chakra-ui/react"

export default function BookADemoButton({
    showSubtitle,
    ...props
}: { showSubtitle?: boolean } & ButtonProps) {
    return (
        <Link to={"/book-a-demo"}>
            <Button
                borderWidth={1}
                colorScheme="pink"
                fontWeight="medium"
                borderColor="blackAlpha.200"
                _hover={{
                    shadow: "md",
                }}
                _active={{
                    shadow: "md",
                }}
                {...props}
            >
                <Box>
                    <Text>Book a demo</Text>
                    {showSubtitle && <Text fontSize="xs">only 8 minutes!</Text>}
                </Box>
            </Button>
        </Link>
    )
}
