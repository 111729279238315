import React, { PropsWithChildren, useContext } from "react"
import { Box, type BoxProps } from "@chakra-ui/layout"
import { useScrollStyle } from "~utils/hooks/use-scroll-style"
import { OfferStateContext } from "~utils/providers/offer-state"
import { SPECIAL_OFFER_BANNER_HEIGHT } from "../special-offer"

interface Props {
    isHomepage: boolean
}

export default function HeaderWrapper({
    isHomepage,
    children,
}: PropsWithChildren<Props>) {
    const { isOfferOpen } = useContext(OfferStateContext)

    if (isHomepage) {
        return (
            <HomepageWrapper isOfferOpen={isOfferOpen}>
                {children}
            </HomepageWrapper>
        )
    }

    return <DefaultWrapper isOfferOpen={isOfferOpen}>{children}</DefaultWrapper>
}

const getSharedStyles = (isOfferOpen: boolean): BoxProps => ({
    as: "header",
    position: "sticky",
    top: isOfferOpen ? `${SPECIAL_OFFER_BANNER_HEIGHT}px` : 0,
    left: 0,
    right: 0,
    zIndex: 999999999998,
})

function HomepageWrapper({
    isOfferOpen,
    children,
}: PropsWithChildren<{ isOfferOpen: boolean }>) {
    const { style } = useScrollStyle({
        threshold: 124,
        style: {
            bg: "brand.500",
            shadow: "lg",
        },
    })

    return (
        <Box
            shadow={{ base: "lg", md: "none" }}
            color="gray.50"
            bg={{ base: "brand.500", md: "transparent" }}
            transition="0.2s"
            {...style}
            {...getSharedStyles(isOfferOpen)}
        >
            {children}
        </Box>
    )
}

function DefaultWrapper({
    isOfferOpen,
    children,
}: PropsWithChildren<{ isOfferOpen: boolean }>) {
    return (
        <Box
            bg="white"
            shadow="blue.lg"
            color="gray.900"
            borderBottomWidth={1}
            borderColor="blueAlpha.400"
            {...getSharedStyles(isOfferOpen)}
        >
            {children}
        </Box>
    )
}
