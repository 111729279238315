import React from "react"
import { Box, Text, SimpleGrid, GridItem } from "@chakra-ui/layout"

import LinkMenu from "./menu"
import { features } from "~config/constants/features"
import MenuOption, { NonMenuOption } from "./menu/option"
import GradientDivider from "~components/shared/gradient-divider"

interface Props {
    useColoredText?: boolean
}

export default function FeaturesButton({ useColoredText }: Props) {
    return (
        <LinkMenu label="Features" useColoredText={useColoredText}>
            <FeatureHeader />
            <GradientDivider my={2} />
            <FeaturesOptions usingMenu />
        </LinkMenu>
    )
}

function FeatureHeader() {
    return (
        <Box px={4} py={2}>
            <Text fontWeight="semibold">Features</Text>
            <Text fontSize="sm" color="gray.500">
                Learn more about the features we offer and how Chckup can help
                you save time, grow your practice, and help more pets.
            </Text>
        </Box>
    )
}

export function FeaturesOptions({
    usingMenu,
    onComplete,
}: {
    usingMenu?: boolean
    onComplete?: () => void
}) {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 0, md: 2 }}>
            {features.map(feature => (
                <GridItem key={feature.label} colSpan={{ base: 1, md: 1 }}>
                    {usingMenu ? (
                        <MenuOption {...feature}>{feature.label}</MenuOption>
                    ) : (
                        <NonMenuOption {...feature} onComplete={onComplete}>
                            {feature.label}
                        </NonMenuOption>
                    )}
                </GridItem>
            ))}
        </SimpleGrid>
    )
}
