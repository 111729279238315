import { BoxProps } from "@chakra-ui/react"
import { useEffect, useState } from "react"

interface Input {
    threshold: number
    style?: BoxProps
}

export function useScrollStyle({ threshold, style }: Input): {
    isScrolled: boolean
    style: BoxProps
} {
    const [isScrolled, setIsScrolled] = useState<boolean>(false)
    const [styleProps, setStyleProps] = useState<BoxProps>({})

    function handleScroll() {
        const scrollY = window.scrollY

        if (scrollY > threshold) {
            setStyleProps(style ?? {})
            setIsScrolled(true)
        } else {
            setStyleProps({})
            setIsScrolled(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return {
        isScrolled,
        style: styleProps,
    }
}
