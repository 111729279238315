import React from "react"
import { Text, keyframes, TextProps } from "@chakra-ui/react"

const rainbowAnimation = keyframes`
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 100%; }
`

const RainbowText = (props: TextProps) => (
    <Text
        sx={{
            backgroundImage: `linear-gradient(124deg, ${"#fff,".repeat(
                36
            )} #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3, ${"#fff,".repeat(
                18
            )} #fff)`,
            backgroundSize: "1800% 1800%",
            animation: `${rainbowAnimation} 12s ease infinite`,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
        }}
        {...props}
    />
)

export default RainbowText
