import React, { createContext, PropsWithChildren } from "react"
import { useLocation } from "@reach/router"

export const OfferStateContext = createContext<{
    isOfferOpen: boolean
    setIsOfferOpen: (value: boolean) => void
}>({
    isOfferOpen: false,
    setIsOfferOpen: () => {},
})

export default function OfferStateProvider({ children }: PropsWithChildren) {
    const { pathname } = useLocation()

    const [isOfferOpen, setIsOfferOpen] = React.useState(true)

    const notOnBookADemo =
        !["book-a-demo"].includes(pathname.split("/")[1]) ||
        pathname.includes("terms-and-conditions")

    return (
        <OfferStateContext.Provider
            value={{
                isOfferOpen: isOfferOpen && notOnBookADemo,
                setIsOfferOpen,
            }}
        >
            {children}
        </OfferStateContext.Provider>
    )
}
