import React from "react"
import { Link } from "gatsby"
import { IconType } from "react-icons"
import { Icon } from "@chakra-ui/icons"
import { Text, HStack } from "@chakra-ui/layout"

export type LinkType = {
    name: string
    href: string
    icon?: IconType
    isExternal?: boolean
}

export default function FooterLink({ name, href, icon, isExternal }: LinkType) {
    if (isExternal) {
        return (
            <a href={href} target="_blank" rel="noreferrer">
                <HStack spacing={4}>
                    {icon && <Icon fontSize="md" as={icon} color="white" />}
                    <Text
                        color="white"
                        _groupHover={{ textDecoration: "underline" }}
                    >
                        {name}
                    </Text>
                </HStack>
            </a>
        )
    }

    return (
        <Link to={href} role="group">
            <HStack spacing={4}>
                {icon && <Icon fontSize="md" as={icon} color="white" />}
                <Text
                    color="white"
                    _groupHover={{ textDecoration: "underline" }}
                >
                    {name}
                </Text>
            </HStack>
        </Link>
    )
}
