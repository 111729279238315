import React from "react"
import { Icon } from "@chakra-ui/icons"
import { FaBars } from "react-icons/fa6"
import { IconButton } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import MobileLinksDrawer from "./drawer"
import { FaTimes } from "react-icons/fa"

export default function MobileMenuButton() {
    const { isOpen, onToggle, onClose } = useDisclosure()

    return (
        <>
            <IconButton
                icon={<Icon as={isOpen ? FaTimes : FaBars} fontSize="2xl" />}
                aria-label="Menu"
                variant="ghost"
                _active={{
                    bg: "blackAlpha.200",
                }}
                _hover={{
                    bg: "blackAlpha.100",
                }}
                display={{ base: "inline", lg: "none" }}
                onClick={onToggle}
            />
            <MobileLinksDrawer isOpen={isOpen} onClose={onClose} />
        </>
    )
}
