import React from "react"
import { Text, type TextProps } from "@chakra-ui/layout"

export default function Subheading({ children, ...rest }: TextProps) {
    return (
        <Text fontSize="lg" fontWeight="semibold" color="navy.700" {...rest}>
            {children}
        </Text>
    )
}
