import React from "react"
import { Image } from "@chakra-ui/image"
import { Box, Text, HStack, AspectRatio, SimpleGrid } from "@chakra-ui/layout"

import LinkMenu from "./menu"
import MenuOption, { NonMenuOption } from "./menu/option"
import { PIMS } from "~components/pages/pims/pims"
import Integrate from "~images/layout/header/pims/integrate.svg"
import GradientDivider from "~components/shared/gradient-divider"

interface Props {
    useColoredText?: boolean
}

export default function PIMSButton({ useColoredText }: Props) {
    return (
        <LinkMenu label="PIMS We Work With" useColoredText={useColoredText}>
            <MenuHeader />
            <GradientDivider my={2} />
            <PimsOptions usingMenu />
        </LinkMenu>
    )
}

function MenuHeader() {
    return (
        <Box px={4} py={2}>
            <HStack spacing={4}>
                <AspectRatio ratio={1} w={12}>
                    <Image src={Integrate} alt="Cloud" />
                </AspectRatio>
                <Box>
                    <Text fontWeight="semibold">PIMS We Work With</Text>
                    <Text fontSize="sm" color="gray.500">
                        Explore how we can work with your system to help
                        streamline your processes
                    </Text>
                </Box>
            </HStack>
        </Box>
    )
}

export function PimsOptions({ usingMenu }: { usingMenu?: boolean }) {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }}>
            {PIMS.map(pims => {
                if (usingMenu) {
                    return (
                        <MenuOption key={pims.name} href={pims.href}>
                            {pims.name}
                        </MenuOption>
                    )
                }
                return (
                    <NonMenuOption key={pims.name} href={pims.href}>
                        {pims.name}
                    </NonMenuOption>
                )
            })}
        </SimpleGrid>
    )
}
