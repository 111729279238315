import { fonts } from "./fonts"
import { shadows } from "./shadows"
import { colors } from "./colors"
import { components } from "./components"
import { extendTheme } from "@chakra-ui/react"

const theme = {
    colors,
    fonts,
    components,
    shadows,
}

export default extendTheme(theme)
