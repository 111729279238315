import React, { forwardRef } from "react"
import type { BoxProps } from "@chakra-ui/layout"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Text, HStack, VStack } from "@chakra-ui/layout"

interface Props extends BoxProps {
    useColoredText?: boolean
}

const MenuButton = forwardRef<HTMLDivElement, Props>(function Wrapper(
    { children, useColoredText, ...rest },
    ref
) {
    return (
        <Box
            ref={ref}
            role="group"
            pt={3}
            pb={1}
            px={3}
            pr={2}
            cursor="pointer"
            transition="all 0.2s"
            {...rest}
        >
            <VStack spacing={1}>
                <HStack>
                    <Text
                        noOfLines={1}
                        fontWeight="semibold"
                        _groupHover={{
                            color: useColoredText ? "brand.600" : "white",
                        }}
                    >
                        {children}
                    </Text>
                    <ChevronDownIcon />
                </HStack>
                <Box
                    h="2px"
                    w="full"
                    maxW="0px"
                    bg={useColoredText ? "brand.500" : "white"}
                    transition="0.2s"
                    _groupHover={{ maxW: "100%" }}
                />
            </VStack>
        </Box>
    )
})

export default MenuButton
