import React from "react"
import { Box, type BoxProps } from "@chakra-ui/layout"

export default function Highlight({ children, ...rest }: BoxProps) {
    return (
        <Box as="span" bg="white" color="navy.700" px={1.5} pb={1} {...rest}>
            {children}
        </Box>
    )
}
