export const colors = {
    brand: {
        50: "#ECFDF5",
        100: "#C7FAE1",
        200: "#7CF4BA",
        300: "#34f99a",
        400: "#10f586",
        500: "#13D879",
        600: "#10BC69",
        700: "#0B8349",
        800: "#085E34",
        900: "#032615",
    },
    brandAlpha: {
        50: "rgba(19, 216, 121, 0.04)",
        100: "rgba(19, 216, 121, 0.06)",
        200: "rgba(19, 216, 121, 0.08)",
        300: "rgba(19, 216, 121, 0.16)",
        400: "rgba(19, 216, 121, 0.24)",
        500: "rgba(19, 216, 121, 0.36)",
        600: "rgba(19, 216, 121, 0.48)",
        700: "rgba(19, 216, 121, 0.64)",
        800: "rgba(19, 216, 121, 0.80)",
        900: "rgba(19, 216, 121, 0.92)",
    },
    blue: {
        50: "#D8F3FD",
        100: "#B1E6FB",
        200: "#8ADAF9",
        300: "#63CEF8",
        400: "#3CC1F6",
        500: "#0CAFF2",
        600: "#0880AF",
        700: "#066488",
        800: "#054761",
        900: "#032B3A",
    },
    blueAlpha: {
        50: "rgba(12, 175, 242, 0.04)",
        100: "rgba(12, 175, 242, 0.06)",
        200: "rgba(12, 175, 242, 0.08)",
        300: "rgba(12, 175, 242, 0.16)",
        400: "rgba(12, 175, 242, 0.24)",
        500: "rgba(12, 175, 242, 0.36)",
        600: "rgba(12, 175, 242, 0.48)",
        700: "rgba(12, 175, 242, 0.64)",
        800: "rgba(12, 175, 242, 0.80)",
        900: "rgba(12, 175, 242, 0.92)",
    },
    pink: {
        50: "#FDD8FD",
        100: "#FBB2FB",
        200: "#F88BF8",
        300: "#F665F6",
        400: "#F43EF4",
        500: "#EA0EEB",
        600: "#C10BC1",
        700: "#9A099A",
        800: "#740774",
        900: "#4D044D",
    },
    pinkAlpha: {
        50: "rgba(234, 14, 235, 0.04)",
        100: "rgba(234, 14, 235, 0.06)",
        200: "rgba(234, 14, 235, 0.08)",
        300: "rgba(234, 14, 235, 0.16)",
        400: "rgba(234, 14, 235, 0.24)",
        500: "rgba(234, 14, 235, 0.36)",
        600: "rgba(234, 14, 235, 0.48)",
        700: "rgba(234, 14, 235, 0.64)",
        800: "rgba(234, 14, 235, 0.80)",
        900: "rgba(234, 14, 235, 0.92)",
    },
    purple: {
        50: "#E8D8FD",
        100: "#D0B1FB",
        200: "#B98AF9",
        300: "#A163F8",
        400: "#8A3CF6",
        500: "#6D0CF2",
        600: "#5709C3",
        700: "#45079C",
        800: "#340675",
        900: "#23044E",
    },
    purpleAlpha: {
        50: "rgba(138, 60, 246, 0.04)",
        100: "rgba(138, 60, 246, 0.06)",
        200: "rgba(138, 60, 246, 0.08)",
        300: "rgba(138, 60, 246, 0.16)",
        400: "rgba(138, 60, 246, 0.24)",
        500: "rgba(138, 60, 246, 0.36)",
        600: "rgba(138, 60, 246, 0.48)",
        700: "rgba(138, 60, 246, 0.64)",
        800: "rgba(138, 60, 246, 0.80)",
        900: "rgba(138, 60, 246, 0.92)",
    },
    green: {
        50: "#C5FCEC",
        100: "#9EFAE0",
        200: "#77F8D3",
        300: "#51F6C7",
        400: "#2AF4BB",
        500: "#0BD59C",
        600: "#0AC28E",
        700: "#089B71",
        800: "#067455",
        900: "#044E39",
    },
    success: {
        100: "#D8FBD4",
        200: "#ACF8AB",
        300: "#7EEA87",
        400: "#5AD671",
        500: "#2BBC54",
        600: "#1FA150",
        700: "#15874B",
        800: "#0D6D44",
        900: "#085A3F",
    },
    info: {
        100: "#CDEEFE",
        200: "#9BD8FD",
        300: "#69BCF9",
        400: "#44A1F4",
        500: "#0977ED",
        600: "#065CCB",
        700: "#0444AA",
        800: "#023089",
        900: "#012271",
    },
    warning: {
        100: "#FFF9CC",
        200: "#FFF399",
        300: "#FFEA67",
        400: "#FFE241",
        500: "#FFD402",
        600: "#DBB201",
        700: "#B79201",
        800: "#937300",
        900: "#7A5D00",
    },
    danger: {
        100: "#FCDCD5",
        200: "#FAB3AC",
        300: "#F28181",
        400: "#E65F6D",
        500: "#D62F50",
        600: "#B8224E",
        700: "#9A174A",
        800: "#7C0E43",
        900: "#66093F",
    },
    navy: {
        50: "#EBF4FF",
        100: "#C3DDFE",
        200: "#73B1FC",
        300: "#378FFB",
        400: "#0F79FA",
        500: "#045CC8",
        600: "#023778",
        700: "#022550",
        800: "#011936",
        900: "#011228",
    },
}
