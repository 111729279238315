import React from "react"
import Layout from "~components/shared/layout"

interface Props {
    element: React.ReactNode
}

const WrapPageElement: React.FC<Props> = ({ element }) => {
    return <Layout>{element}</Layout>
}

export default WrapPageElement
