export const shadows = {
    outline: "0 0 0 3px rgba(24, 199, 149, 0.3)",
    brand: {
        xs: "0 0 0 1px rgba(11, 213, 156, 0.3)",
        sm: "0 1px 2px 0 rgba(11, 213, 156, 0.4)",
        base: "0 1px 3px 0 rgba(11, 213, 156, 0.2), 0 1px 2px 0 rgba(11, 213, 156, 0.12)",
        md: "0 4px 6px -1px rgba(11, 213, 156, 0.1), 0 2px 4px -1px rgba(11, 213, 156, 0.12)",
        lg: "0 10px 15px -3px rgba(11, 213, 156, 0.2), 0 4px 6px -2px rgba(11, 213, 156, 0.1)",
        xl: "0 20px 25px -5px rgba(11, 213, 156, 0.2), 0 10px 10px -5px rgba(11, 213, 156, 0.08)",
        "2xl": "0 25px 50px -12px rgba(11, 213, 156, 0.25)",
    },
    blue: {
        xs: "0 0 0 1px rgba(12, 175, 242, 0.3)",
        sm: "0 1px 2px 0 rgba(12, 175, 242, 0.4)",
        base: "0 1px 3px 0 rgba(12, 175, 242, 0.2), 0 1px 2px 0 rgba(12, 175, 242, 0.12)",
        md: "0 4px 6px -1px rgba(12, 175, 242, 0.1), 0 2px 4px -1px rgba(12, 175, 242, 0.12)",
        lg: "0 10px 15px -3px rgba(12, 175, 242, 0.2), 0 4px 6px -2px rgba(12, 175, 242, 0.1)",
        xl: "0 20px 25px -5px rgba(12, 175, 242, 0.2), 0 10px 10px -5px rgba(12, 175, 242, 0.08)",
        "2xl": "0 25px 50px -12px rgba(12, 175, 242, 0.25)",
    },
    pink: {
        xs: "0 0 0 1px rgba(234, 14, 235, 0.3)",
        sm: "0 1px 2px 0 rgba(234, 14, 235, 0.4)",
        base: "0 1px 3px 0 rgba(234, 14, 235, 0.2), 0 1px 2px 0 rgba(234, 14, 235, 0.12)",
        md: "0 4px 6px -1px rgba(234, 14, 235, 0.1), 0 2px 4px -1px rgba(234, 14, 235, 0.12)",
        lg: "0 10px 15px -3px rgba(234, 14, 235, 0.2), 0 4px 6px -2px rgba(234, 14, 235, 0.1)",
        xl: "0 20px 25px -5px rgba(234, 14, 235, 0.2), 0 10px 10px -5px rgba(234, 14, 235, 0.08)",
        "2xl": "0 25px 50px -12px rgba(234, 14, 235, 0.25)",
    },
    purple: {
        xs: "0 0 0 1px rgba(109, 12, 242, 0.3)",
        sm: "0 1px 2px 0 rgba(109, 12, 242, 0.4)",
        base: "0 1px 3px 0 rgba(109, 12, 242, 0.2), 0 1px 2px 0 rgba(109, 12, 242, 0.12)",
        md: "0 4px 6px -1px rgba(109, 12, 242, 0.1), 0 2px 4px -1px rgba(109, 12, 242, 0.12)",
        lg: "0 10px 15px -3px rgba(109, 12, 242, 0.2), 0 4px 6px -2px rgba(109, 12, 242, 0.1)",
        xl: "0 20px 25px -5px rgba(109, 12, 242, 0.2), 0 10px 10px -5px rgba(109, 12, 242, 0.08)",
        "2xl": "0 25px 50px -12px rgba(109, 12, 242, 0.25)",
    },
}
