import { ComponentStyleConfig, defineStyle } from "@chakra-ui/react"

const Input: ComponentStyleConfig = {
    baseStyle: {},
    sizes: {
        xl: defineStyle({
            field: {
                h: 16,
                px: 6,
                fontSize: "lg",
            },
        }),
    },
    variants: {},
    defaultProps: {},
}

const Heading: ComponentStyleConfig = {
    baseStyle: {},
    sizes: {
        "5xl": defineStyle({
            fontSize: ["7xl", null, "8xl"],
            lineHeight: 1,
        }),
    },
}

const Divider: ComponentStyleConfig = {
    variants: {
        gradient: defineStyle({
            bgGradient:
                "linear(to-r, rgba(170,170,170,0.1) 0%, rgba(170,170,170,0.5) 50%, rgba(170,170,170,0.1) 100%)",
            h: "1px",
        }),
    },
}

const xl = defineStyle({
    fontSize: "xl",
    px: 6,
    h: 12,
    borderRadius: "md",
})

const Button: ComponentStyleConfig = {
    variants: {
        subtle: (props: any) => ({
            bg:
                props.colorMode === "dark"
                    ? `${props.colorScheme}.700`
                    : `${props.colorScheme}.100`,
            color:
                props.colorMode === "dark"
                    ? `${props.colorScheme}.100`
                    : `${props.colorScheme}.800`,
            _hover: {
                filter: "brightness(0.95)",
            },
        }),
    },
    sizes: {
        xl,
    },
}

export const components = {
    Input,
    Heading,
    Divider,
    Button,
}
