import React from "react"
import { Box, VStack } from "@chakra-ui/react"

import ResponsiveBlock from "~components/shared/layout/responsive-block"
import SectionHeading from "../../text/heading"
import Subheading from "../../text/subheading"
import Highlight from "../../text/highlight"
import Pattern from "~images/pages/home/hero/pattern.png"
import BookADemoButton from "~components/shared/book-a-demo-button"

export default function CallToAction() {
    return (
        <Box bg="brand.600" position="relative">
            <CTABackground />
            <ResponsiveBlock isWide py={20} position="relative">
                <VStack spacing={8}>
                    <SectionHeading
                        fontWeight="extrabold"
                        textAlign="center"
                        size={{ base: "xl", lg: "2xl" }}
                        color="white"
                    >
                        Start running your practice like it's 2024.
                    </SectionHeading>
                    <Subheading
                        textAlign="center"
                        fontSize={{ base: "lg", lg: "2xl" }}
                        color="white"
                    >
                        Get the tools you need so you can focus on what matters.{" "}
                        <Highlight>Helping more pets.</Highlight>
                    </Subheading>
                    <BookADemoButton px={12} size="xl" showSubtitle />
                </VStack>
            </ResponsiveBlock>
        </Box>
    )
}

function CTABackground() {
    return (
        <>
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="brandAlpha.600"
                clipPath="circle(25% at 50% -25%)"
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="brandAlpha.400"
                clipPath="circle(45% at 50% -45%)"
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="brandAlpha.400"
                clipPath="circle(65% at 50% -65%)"
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Pattern}
            />
        </>
    )
}
