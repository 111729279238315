import React from "react"
import { Image } from "@chakra-ui/image"
import { AspectRatio } from "@chakra-ui/layout"

import OnlineSchedling from "~images/layout/header/features/online-scheduling.svg"
import Reminders from "~images/layout/header/features/reminders.svg"
import Analytics from "~images/layout/header/features/analytics.svg"
import Reviews from "~images/layout/header/features/reviews.svg"
import TwoWayMessaging from "~images/layout/header/features/two-way-messaging.svg"
import Loyalty from "~images/layout/header/features/loyalty.svg"
import ClientPortal from "~images/layout/header/features/client-portal.svg"
import Enterprise from "~images/layout/header/features/enterprise.svg"

export type Feature = {
    label: string
    href: string
    description: string
    flair: React.ReactNode
}

export const features: Feature[] = [
    {
        label: "Real-Time Online Booking",
        href: "/features/realtime-booking",
        description: "See how we can save you over 8 hours per week",
        flair: (
            <FeatureFlair
                src={OnlineSchedling}
                alt="Real-Time Online Booking"
            />
        ),
    },
    {
        label: "Client Portal",
        href: "/features/client-portal",
        description: "Give clients access to their pet's records and more",
        flair: <FeatureFlair src={ClientPortal} alt="Client Portal" />,
    },
    {
        label: "Reminders",
        href: "/features/reminders",
        description: "Boost revenue, reduce no-shows, and track conversions",
        flair: <FeatureFlair src={Reminders} alt="Reminders" />,
    },
    {
        label: "Reviews",
        href: "/features/reviews",
        description: "Manage your online reputation & send review requests",
        flair: <FeatureFlair src={Reviews} alt="Reviews" />,
    },
    {
        label: "Two-Way Messaging",
        href: "/features/messaging",
        description: "Communicate with clients through text and email",
        flair: <FeatureFlair src={TwoWayMessaging} alt="Two-Way Messaging" />,
    },
    {
        label: "Client Loyalty Program",
        href: "/features/loyalty",
        description: "Reward your clients and keep them coming back",
        flair: <FeatureFlair src={Loyalty} alt="Client Loyalty Program" />,
    },
    {
        label: "Practice Analytics",
        href: "/features/analytics",
        description:
            "Get ahead with the most advanced analytics in the industry",
        flair: <FeatureFlair src={Analytics} alt="Practice Analytics" />,
    },
    {
        label: "Enterprise",
        href: "/features/enterprise",
        description: "Understand your group like never before",
        flair: <FeatureFlair src={Enterprise} alt="Enterprise" />,
    },
]

export function FeatureFlair({ src, alt }: { src: string; alt: string }) {
    return (
        <AspectRatio ratio={1} width={10}>
            <Image src={src} alt={alt} w="full" h="full" />
        </AspectRatio>
    )
}
