import React from "react"
import { Heading, type HeadingProps } from "@chakra-ui/layout"

export default function SectionHeading({ children, ...rest }: HeadingProps) {
    return (
        <Heading
            as="h2"
            color="navy.700"
            fontWeight="extrabold"
            size={{ base: "xl", xl: "3xl" }}
            textAlign={{ base: "center", lg: "left" }}
            {...rest}
        >
            {children}
        </Heading>
    )
}
