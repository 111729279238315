import React from "react"
import { Box, BoxProps } from "@chakra-ui/layout"

interface Props extends BoxProps {
    isWide?: boolean
    children: React.ReactNode
}

const ResponsiveBlock: React.FC<Props> = ({ children, isWide, ...rest }) => {
    return (
        <Box
            maxW={{ base: "100dvw", lg: isWide ? "1440px" : "1096px" }}
            mx={{ base: "none", lg: "auto" }}
            px={{ base: 6, sm: 12, md: 24, "2xl": "0px" }}
            {...rest}
        >
            {children}
        </Box>
    )
}

export default ResponsiveBlock
