import React, { useContext } from "react"
import { Box } from "@chakra-ui/layout"
import { useLocation } from "@reach/router"

import Header from "./header"
import Footer from "./footer"
import SpecialOfferBanner from "./special-offer"
import { OfferStateContext } from "~utils/providers/offer-state"

interface Props {
    children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
    const { pathname } = useLocation()
    const { isOfferOpen } = useContext(OfferStateContext)

    const showNav =
        !["book-a-demo"].includes(pathname.split("/")[1]) ||
        pathname.includes("terms-and-conditions")

    return (
        <Box position="relative" h="full">
            {/* {isOfferOpen && <SpecialOfferBanner />} */}
            {showNav && <Header />}
            {children}
            {showNav && <Footer />}
        </Box>
    )
}

export default Layout
