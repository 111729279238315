import React from "react"
import { Text, VStack, GridItem, SimpleGrid } from "@chakra-ui/layout"

import FooterLink, { LinkType } from "./link"

interface Props {
    title: string
    links: LinkType[]
    double?: boolean
}

export default function LinkColumn({ title, links, double = false }: Props) {
    return (
        <GridItem colSpan={double ? 2 : 1}>
            <VStack alignItems="flex-start" spacing={4}>
                <Text fontWeight="bold" color="white">
                    {title}
                </Text>
                {double ? (
                    <SimpleGrid columns={2} columnGap={2} rowGap={4}>
                        {links.map(link => (
                            <FooterLink key={link.name} {...link} />
                        ))}
                    </SimpleGrid>
                ) : (
                    links.map(link => <FooterLink key={link.name} {...link} />)
                )}
            </VStack>
        </GridItem>
    )
}
