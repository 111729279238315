import React, { PropsWithChildren } from "react"
import type { MenuProps, MenuListProps } from "@chakra-ui/menu"
import { Menu, MenuList, MenuButton as ChakraMB } from "@chakra-ui/menu"

import MenuButton from "./button"
import { useDisclosure } from "@chakra-ui/react"

interface Props extends MenuProps {
    label: string
    listOverrides?: MenuListProps
    useColoredText?: boolean
}

export default function LinkMenu({
    label,
    listOverrides,
    useColoredText,
    children,
    ...rest
}: PropsWithChildren<Props>) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Menu placement="bottom" isOpen={isOpen} gutter={0} {...rest}>
            <ChakraMB
                as={MenuButton}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                useColoredText={useColoredText}
            >
                {label}
            </ChakraMB>
            <MenuList
                py={1}
                px={2}
                rounded="xl"
                color="gray.900"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                {...listOverrides}
            >
                {children}
            </MenuList>
        </Menu>
    )
}
