import React from "react"

import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/900.css"
import "@fontsource/nunito-sans/300.css"
import "@fontsource/nunito-sans/400.css"
import "@fontsource/nunito-sans/600.css"
import "@fontsource/nunito-sans/700.css"
import "@fontsource/nunito-sans/800.css"
import "@fontsource/nunito-sans/900.css"
import "@fontsource/crimson-pro/400.css"
import "@fontsource/crimson-pro/500.css"
import "@fontsource/crimson-pro/600.css"
import "@fontsource/crimson-pro/700.css"
import "@fontsource/crimson-pro/800.css"
import "@fontsource/crimson-pro/900.css"
import "@fontsource/inter/100.css"
import "@fontsource/inter/200.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/800.css"
import "@fontsource/inter/900.css"
import "@fontsource/handlee"

import { LocationProvider } from "@reach/router"
import OfferStateProvider from "~utils/providers/offer-state"

interface Props {
    element: React.ReactNode
}

const WrapRootElement: React.FC<Props> = ({ element }) => (
    <LocationProvider>
        <OfferStateProvider>{element}</OfferStateProvider>
    </LocationProvider>
)

export default WrapRootElement
