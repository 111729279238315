import AVImark from "~images/pages/pims/logos/avimark.png"
import Cornerstone from "~images/pages/pims/logos/cornerstone.png"
import EzyVet from "~images/pages/pims/logos/ezyVet.png"
import ImproMed from "~images/pages/pims/logos/impromed.png"
import Provet from "~images/pages/pims/logos/provet.png"
import Pulse from "~images/pages/pims/logos/pulse.png"
import Rhapsody from "~images/pages/pims/logos/rhapsody.png"
import Shepherd from "~images/pages/pims/logos/shepherd.png"

export const PIMS = [
    {
        name: "Covetrus AVImark",
        href: "/pims/avimark",
        logo: AVImark,
    },
    {
        name: "IDEXX Cornerstone",
        href: "/pims/cornerstone",
        logo: Cornerstone,
    },
    {
        name: "EzyVet",
        href: "/pims/ezyvet",
        logo: EzyVet,
    },
    {
        name: "ImproMed/Infinity",
        href: "/pims/impromed",
        logo: ImproMed,
    },
    {
        name: "Provet Cloud",
        href: "/pims/provet",
        logo: Provet,
    },
    {
        name: "Covetrus Pulse / eVetPractice",
        href: "/pims/pulse",
        logo: Pulse,
    },
    {
        name: "Rhapsody",
        href: "/pims/rhapsody",
        logo: Rhapsody,
    },
    {
        name: "Shepherd",
        href: "/pims/shepherd",
        logo: Shepherd,
    },
]
