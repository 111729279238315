import React from "react"
import { Link } from "gatsby"
import { Image } from "@chakra-ui/image"
import { Box } from "@chakra-ui/layout"

import Green from "~images/logo/png/logo.png"
import White from "~images/logo/png/logo-white.png"

interface Props {
    useColoredLogo?: boolean
}

export default function HeaderLogo({ useColoredLogo }: Props) {
    return (
        <Link to={"/"}>
            <Box>
                <Image
                    src={useColoredLogo ? Green : White}
                    alt="Chckup Logo"
                    width={102}
                />
            </Box>
        </Link>
    )
}
