import React from "react"
import { SimpleGrid, GridItem } from "@chakra-ui/layout"

import LinkMenu from "./menu"
import { resources } from "~config/constants/resources"
import MenuOption, { NonMenuOption } from "./menu/option"

interface Props {
    useColoredText?: boolean
}

export default function ResourcesButton({ useColoredText }: Props) {
    return (
        <LinkMenu label="Resources" useColoredText={useColoredText}>
            <ResourcesOptions usingMenu />
        </LinkMenu>
    )
}

export function ResourcesOptions({
    usingMenu,
    onComplete,
}: {
    usingMenu?: boolean
    onComplete?: () => void
}) {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 0, md: 2 }}>
            {resources.map((resource, index) => (
                <GridItem
                    key={resource.label}
                    colSpan={{ base: 1, md: [0, 1].includes(index) ? 2 : 1 }}
                >
                    {usingMenu ? (
                        <MenuOption {...resource}>{resource.label}</MenuOption>
                    ) : (
                        <NonMenuOption {...resource} onComplete={onComplete}>
                            {resource.label}
                        </NonMenuOption>
                    )}
                </GridItem>
            ))}
        </SimpleGrid>
    )
}
