import React, { PropsWithChildren } from "react"
import { Link } from "gatsby"
import { MenuItem } from "@chakra-ui/menu"
import { Box, Text, HStack } from "@chakra-ui/layout"
import { ArrowForwardIcon } from "@chakra-ui/icons"

interface Props {
    href: string
    description?: string
    flair?: React.ReactNode
    isExternal?: boolean
    onComplete?: () => void
}

export default function MenuOption({
    href,
    description,
    flair,
    isExternal,
    children,
}: PropsWithChildren<Props>) {
    return (
        <Box role="group">
            <MenuItem
                as={isExternal ? "a" : Link}
                // @ts-ignore
                to={isExternal ? undefined : href}
                href={isExternal ? href : undefined}
                w="fit-content"
                px={5}
                py={3}
                _focus={{
                    bg: "transparent",
                }}
                _hover={{
                    bg: "transparent",
                }}
            >
                <HStack spacing={4}>
                    {flair}
                    <Box>
                        <HStack spacing={3}>
                            <Text
                                fontWeight="semibold"
                                transition="all 0.2s"
                                _groupHover={{
                                    transform: "translateX(4px)",
                                }}
                            >
                                {children}
                            </Text>
                            <ArrowForwardIcon
                                transform="translateX(-4px)"
                                transition="opacity 0.1s, transform 0.3s"
                                opacity={0}
                                _groupHover={{
                                    opacity: 1,
                                    transform: "translateX(4px)",
                                }}
                            />
                        </HStack>
                        {description && (
                            <Text fontSize="sm" color="gray.500">
                                {description}
                            </Text>
                        )}
                    </Box>
                </HStack>
            </MenuItem>
        </Box>
    )
}

export function NonMenuOption({
    href,
    description,
    flair,
    children,
    onComplete,
}: PropsWithChildren<Props>) {
    return (
        <Box role="group">
            <Box
                as={Link}
                to={href}
                w="fit-content"
                px={5}
                py={{ base: 0, md: 3 }}
                _focus={{
                    bg: "transparent",
                }}
                _hover={{
                    bg: "transparent",
                }}
                onClick={onComplete}
            >
                <HStack spacing={4}>
                    {flair}
                    <Box>
                        <HStack spacing={3}>
                            <Text
                                fontWeight="semibold"
                                transition="all 0.2s"
                                _groupHover={{
                                    transform: "translateX(4px)",
                                }}
                            >
                                {children}
                            </Text>
                            <ArrowForwardIcon
                                transform="translateX(-4px)"
                                transition="opacity 0.1s, transform 0.3s"
                                opacity={0}
                                _groupHover={{
                                    opacity: 1,
                                    transform: "translateX(4px)",
                                }}
                            />
                        </HStack>
                        {description && (
                            <Text fontSize="sm" color="gray.500">
                                {description}
                            </Text>
                        )}
                    </Box>
                </HStack>
            </Box>
        </Box>
    )
}
