import React, { useContext } from "react"
import { Text, VStack, GridItem, SimpleGrid } from "@chakra-ui/layout"
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
} from "@chakra-ui/modal"
import { mobileLinks } from "./links"
import { LoginOptions } from "../links/login"
import { OfferStateContext } from "~utils/providers/offer-state"
import { NonMenuOption } from "../links/menu/option"

interface Props {
    isOpen: boolean
    onClose: () => void
}

export default function MobileLinksDrawer({ isOpen, onClose }: Props) {
    const { isOfferOpen } = useContext(OfferStateContext)

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="full">
            <DrawerOverlay />
            <DrawerContent pt="58px" h="100dvh" overflowY="scroll">
                <DrawerBody>
                    <SimpleGrid columns={1} gap={0}>
                        {mobileLinks.map(link => (
                            <GridItem key={link.label} colSpan={1}>
                                <NonMenuOption {...link} onComplete={onClose}>
                                    {link.label}
                                </NonMenuOption>
                            </GridItem>
                        ))}
                    </SimpleGrid>
                    <Text fontWeight="semibold" color="navy.700">
                        Log In
                    </Text>
                    <VStack alignItems="flex-start" spacing={0}>
                        <LoginOptions />
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
